<template>
  <div v-if="item">
    <tour :tourKey="'lead_create'" :key="item.lead.xrf_result" />
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-select
              class="lead-assessment-method-step"
              label="Assessment Method*"
              v-model="item.assessment_method_id"
              :items="
                assessmentMethods.filter(
                  (assessment) =>
                    assessment.contaminate_id == LEAD && assessment.id != BMIS_LEAD
                )
              "
              messages="*Required"
              item-text="name"
              item-value="id"
              outlined
              v-validate="'required'"
              :error-messages="errors.collect('assessment method')"
              data-vv-name="assessment method"
              placeholder="Select assessment method of the item"
              required
            ></v-select>
          </v-col>
          <v-col
            sm="6"
            cols="12"
            class="pt-0"
            v-if="item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED"
          >
            <v-text-field
              data-cy="report-as"
              spellcheck="true"
              label="Reported as*"
              messages="*Required"
              v-model.lazy="item.reported_sample.name"
              v-validate="'required|max:190'"
              counter
              outlined
              maxlength="190"
              placeholder="Name of the report where this item is sampled"
              required
              data-vv-name="reported as"
              :error-messages="errors.collect('reported as')"
              :key="5"
            ></v-text-field>
          </v-col>
          <v-col
            sm="6"
            cols="12"
            class="pt-0"
            v-if="item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED"
          >
            <v-text-field
              class="lead-sample-id-step"
              spellcheck="true"
              label="Reported Sample ID*"
              :messages="`${!item.lead.xrf_result ? '*' : ''}Required`"
              v-model.lazy="item.reported_sample.sample_number"
              v-validate="`${!item.lead.xrf_result ? 'required|max:190' : ''}`"
              counter
              outlined
              maxlength="190"
              :error-messages="errors.collect('reported sample number')"
              data-vv-name="reported sample number"
              placeholder="Sample ID from the report where the item was sampled"
              :required="!item.lead.xrf_result"
              :key="6"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              spellcheck="true"
              label="Name*"
              outlined
              v-model.lazy="item.name"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              placeholder="Name of item"
              messages="*Required"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-link-word">
            <v-text-field
              spellcheck="true"
              outlined
              label="Link word*"
              v-model.lazy="item.link"
              messages="*Required"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('link word')"
              data-vv-name="link word"
              placeholder="Word linking item to location"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-location">
            <v-text-field
              spellcheck="true"
              label="Location*"
              messages="*Required"
              outlined
              v-model.lazy="item.description"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('location')"
              data-vv-name="location"
              placeholder="Location of the item"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="item.assessment_method_id != LEAD_INACCESSIBLE">
          <v-col sm="4" cols="12" data-cy="lead-building-material">
            <v-text-field
              spellcheck="true"
              label="Building material"
              outlined
              v-model.lazy="item.lead.building_material"
              v-validate="'required|max:190'"
              data-vv-name="building material"
              :error-messages="errors.collect('building material')"
              counter
              maxlength="190"
              placeholder="Building Material"
              messages="*Required"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" data-cy="lead-colour">
            <v-text-field
              spellcheck="true"
              outlined
              label="Paint Colour*"
              v-model.lazy="item.lead.colour"
              messages="*Required"
              v-validate="'required|max:190'"
              data-vv-name="colour"
              :error-messages="errors.collect('colour')"
              counter
              maxlength="190"
              placeholder="Paint Colour"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" data-cy="lead-paint-condition">
            <v-select
              :items="paint_condition"
              spellcheck="true"
              label="Paint Condition*"
              outlined
              v-model.lazy="item.lead.paint_condition"
              v-validate="'required|max:190'"
              data-vv-name="paint condition"
              :error-messages="errors.collect('paint condition')"
              placeholder="Paint Condition"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col data-cy="lead-bag-code" v-if="item.assessment_method_id == LEAD_SAMPLED">
            <v-text-field
              spellcheck="true"
              label="Sample ID*"
              v-validate="'required'"
              messages="*Required"
              required
              maxlength="10"
              :success="sampleIdInput.success"
              :loading="sampleIdInput.loading"
              :error-messages="sampleIdInput.errorMessage"
              data-vv-name="sample ID"
              outlined
              placeholder="Enter the item Sample ID"
              v-model="item.sample.bag_code"
              @change.native="bagCheck"
              class="sample-id-step"
              key="lead-sample-id"
            ></v-text-field>
          </v-col>
          <v-col v-if="item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED">
            <v-text-field
              spellcheck="true"
              persistent-hint
              type="number"
              label="Laboratory analysis"
              :messages="`${!item.lead.xrf_result ? '*' : ''}Required`"
              hint="Please enter result in PPM"
              outlined
              placeholder="Please enter result in PPM"
              v-model.lazy="item.lead.laboratory_analysis"
              :error-messages="errors.collect('laboratory result')"
              data-vv-name="laboratory result"
              :required="!item.lead.xrf_result"
              v-validate="!item.lead.xrf_result ? `required` : ''"
            ></v-text-field>
          </v-col>
          <v-col v-if="item.assessment_method_id != LEAD_INACCESSIBLE">
            <v-text-field
              v-validate="
                `${
                  item.assessment_method_id == LEAD_XRF ||
                  (!item.lead.laboratory_analysis &&
                    item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED)
                    ? 'required'
                    : ''
                }`
              "
              data-vv-name="XRF result"
              :error-messages="errors.collect('XRF result')"
              :messages="`${
                item.assessment_method_id == LEAD_XRF ||
                (!item.lead.laboratory_analysis &&
                  item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED)
                  ? '*required'
                  : ''
              }`"
              :label="`${
                item.assessment_method_id == LEAD_XRF ||
                (!item.lead.laboratory_analysis &&
                  item.assessment_method_id == LEAD_PREVIOUSLY_SAMPLED)
                  ? '*'
                  : ''
              }XRF Result`"
              v-model.lazy="item.lead.xrf_result"
              class="xrf-result-step"
              spellcheck="true"
              persistent-hint
              type="number"
              hint="Please enter result in PPM"
              outlined
              placeholder="Please enter result in PPM"
              required
              key="xrf-result"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="!item.lead.xrf_result && item.assessment_method_id == LEAD_SAMPLED"
          >
            <v-select
              class="colour-metric-step"
              label="Colour metric"
              persistent-hint
              :items="colour_metrics"
              item-text="name"
              outlined
              clearable
              item-value="id"
              v-model.lazy="item.lead.colour_metric"
              placeholder="Select colour metric for this item"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              autocomplete="off"
              spellcheck="true"
              label="Comments"
              v-model="item.comment"
              outlined
              placeholder="Enter comments about the item"
              data-cy="comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="item.assessment_method_id">
          <v-col v-cloak @dragover.prevent>
            <v-file-input
              v-model="file"
              accept="image/*"
              :placeholder="!file ? 'Item Photo' : item.name"
              label="Item Photo"
              v-validate="'image'"
              outlined
              :error-messages="errors.collect('image')"
              data-vv-name="image"
              hint="Image of the item"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row justify="center" style="max-width: 500px; margin: auto">
          <v-col>
            <a-img :src="imageDisplay" v-if="reader && imageDisplay && item.file"></a-img>
            <a-img
              v-else-if="item.id"
              :src="`/api/image/item/${item.id}?${item.updated_at}`"
            ></a-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="text-center">Draw polygon</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <polygon-menu
                    :url="`/api/image/floor/${$route.params.floor}/image?updated=${selectedFloor.updated_at}`"
                    :drawingUrl="
                      item.id
                        ? `/api/drawing-image/item/${$route.params.floor}/${item.id}`
                        : `/api/drawing-image/item/${$route.params.floor}`
                    "
                    v-if="$route.params.floor"
                    :aItem.sync="item"
                  ></polygon-menu>
                </div>
              </template>
              <span>Draw Polygon</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="polygonInvalid">
          <v-col class="pa-2 warning center-text">
            <p class="pt-2" data-cy="warning-polygon-text">Please place a polygon</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="createLead"
              data-cy="next"
              class="float-right"
              >{{ item.id ? "Update" : "Create" }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PolygonMenu from "./PolygonDrawerMenu.vue";
import Tour from "./Tour.vue";
import mixin from "../helpers/mixin";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";

export default {
  props: ["aItem"],
  components: {
    PolygonMenu,
    Tour,
  },
  mixins: [mixin],
  created() {
    this.LEAD = 2;
    this.LEAD_PREVIOUSLY_SAMPLED = ASSESSMENT_METHODS.LEAD_PREVIOUSLY_SAMPLED;
    this.LEAD_SAMPLED = ASSESSMENT_METHODS.LEAD_SAMPLED;
    this.LEAD_XRF = ASSESSMENT_METHODS.LEAD_XRF;
    this.LEAD_INACCESSIBLE = ASSESSMENT_METHODS.LEAD_INACCESSIBLE;
    this.BMIS_LEAD = ASSESSMENT_METHODS.BMIS_LEAD;
  },
  mounted() {
    this.image = `/api/image/item/${this.$route.params.item}`;
    this.loaded = true;
    this.reader = new FileReader();
    this.reader.onload = (e) => {
      this.imageDisplay = e.target.result;
    };

    this.item.building_id = this.building.id;
    this.item.room_id = this.room.id;
  },
  data: () => ({
    polygonInvalid: false,
    loaded: false,
    imageFile: null,
    imageDisplay: null,
    reader: null,
    file: null,
    sampleIdInput: {
      success: false,
      failed: false,
      loading: false,
      errorMessage: null,
    },
    colour_metrics: [
      {
        id: 0,
        name: "Negative",
      },
      {
        id: 1,
        name: "Positive",
      },
    ],
    paint_condition: ["Good", "Moderate", "Poor"],
  }),
  computed: {
    ...mapGetters([
      "invalidInput",
      "selectedFloor",
      "building",
      "room",
      "survey",
      "amountTypes",
      "analysisTypes",
      "assessmentMethods",
    ]),
    item: {
      get() {
        return this.aItem;
      },
      set(updated) {
        this.$emit("update:aItem", updated);
      },
    },
  },
  watch: {
    file(updated) {
      if (!updated) return;
      this.addFileOptimization(updated).then((result) => {
        this.imageDisplay = result;
        this.item.file = result;
      });
    },
  },
  methods: {
    ...mapActions(["checkBagCode"]),
    imageChange(updated) {
      this.item.file = updated;
    },
    bagCheck() {
      if (this.item.assessment_method_id != this.LEAD_SAMPLED) return false;
      if (!this.item.sample.bag_code) {
        this.sampleIdInput.success = false;
        this.sampleIdInput.failed = true;
        this.sampleIdInput.errorMessage = "Please enter a Sample ID";
        this.sampleIdInput.loading = false;
        return;
      }
      this.sampleIdInput.loading = true;
      return this.checkBagCode({
        building_id: this.$route.params.building,
        bag_code: this.item.sample.bag_code,
        contaminate_id: this.item.contaminate_id,
      })
        .then(() => {
          this.sampleIdInput.success = true;
          this.sampleIdInput.failed = false;
          this.sampleIdInput.errorMessage = null;
        })
        .catch((err) => {
          this.sampleIdInput.success = false;
          this.sampleIdInput.failed = true;
          this.sampleIdInput.errorMessage = err.response.data.message;
        })
        .finally(() => {
          this.sampleIdInput.loading = false;
        });
    },
    createLead() {
      this.polygonInvalid = false;
      this.$validator.validateAll().then((result) => {
        if (!result) return this.$vuetify.goTo(this.invalidInput(this.$validator));
        if (
          (!this.item.sample.bag_code || this.sampleIdInput.failed) &&
          this.item.assessment_method_id == this.LEAD_SAMPLED
        ) {
          let sampleIdEl = this.$validator.fields.items.find(
            (item) => item.name == "sample ID"
          );
          if (this.item.sample.bag_code) {
            this.sampleIdInput.errorMessage = "Bag code is already in use";
          } else {
            this.sampleIdInput.errorMessage = "Sample ID field is required";
          }
          this.$vuetify.goTo(sampleIdEl.el);
          return;
        }
        if (this.item.layer.polygons.length == 0) {
          this.polygonInvalid = true;
          return;
        }
        if (this.$route.params.item) {
          this.$emit("update");
          return;
        }
        this.$emit("save");
      });
    },
  },
};
</script>
