<template>
  <div class="text-center justify-center">
    <canvas id="canvasDisplay"></canvas>
  </div>
</template>

<script>
import { FabricDrawing } from "fabric-tool";
import { colors } from "vuetify/lib";

export default {
  props: ["updated", "polygonData", "backgroundImageUrl", "colors", "loading"],
  data: () => ({
    context: null,
    canvas: null,
    parent: null,
    scale: null,
    polygon: null,
    fill: { r: 255, g: 99, b: 71, a: 0.2 },
    stroke: { r: 255, g: 99, b: 71, a: 0.6 },
    config: {
      canvasEl: "canvasDisplay",
      viewOnly: true,
      maxShapes: 1,
      cornerColor: "red",
      cornerStyle: "circle",
      stroke: "red",
      polygonFill: "rgba(255, 99, 71, 0.4)",
      cornerSize: 16,
      radius: 6,
      strokeWidth: 3,
      shapes: ["polygon", "polyline", "text"],
      maxLabels: 1,
      polyLineWidth: 3,
      textColor: "red",
    },
    fabricDrawing: null,
  }),
  mounted() {
    this.initCanvas();
  },
  methods: {
    draw() {
      if (this.polygonData) {
        this.drawAll();
      }
    },
    drawAll() {
      let shapeData = [];
      if (
        this.polygonData &&
        this.polygonData.polygons &&
        this.polygonData.polygons.length > 0
      ) {
        for (let polygon of this.polygonData.polygons) {
          let points = polygon.points.map((point) => {
            return {
              x: Number(point[0]),
              y: Number(point[1]),
            };
          });
          let shape = {
            type: polygon.type ? polygon.type : "polygon",
            points: points,
          };
          shapeData.push(shape);
        }
      }
      if (
        this.polygonData &&
        this.polygonData.labels &&
        this.polygonData.labels.length > 0
      ) {
        for (let label of this.polygonData.labels) {
          let points = label.points.map((point) => {
            return {
              x: Number(point[0]),
              y: Number(point[1]),
            };
          });
          let text = {
            type: "text",
            scaleX: label.scaleX ?? 1,
            scaleY: label.scaleY ?? 1,
            angle: label.angle ?? 0,
            fill: this.colors.stroke
              ? this.getRGBA(this.colors.stroke)
              : label.fill ?? "red",
            stroke: this.colors.stroke
              ? this.getRGBA(this.colors.stroke)
              : label.stroke ?? "red",
            fontSize: label.fontSize ?? 30,
            textValue: label.textValue ?? "Default Text",
            points: points,
          };

          shapeData.push(text);
        }
      }

      if (shapeData.length > 0) {
        this.fabricDrawing.completeObjects(JSON.stringify(shapeData));
      }
    },
    getRGBA(color) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a - 0.01})`;
    },
    async initCanvas() {
      try {
        const response = await this.axios.get(this.backgroundImageUrl);
        this.config.backgroundImg = response.data;
        this.config.polygonFill = this.colors.fill
          ? this.getRGBA(this.colors.fill)
          : this.config.polygonFill;
        this.config.stroke = this.colors.stroke
          ? this.getRGBA(this.colors.stroke)
          : this.config.stroke;
        this.fabricDrawing = new FabricDrawing(this.config);
        this.fabricDrawing.setBackgroundImgUrl(this.config.backgroundImg).then(() => {
          this.draw();
          this.$emit("update:loading", false);
        });
      } catch (error) {
        console.log(error);
      }
    },
    resetCanvas() {
      this.fabricDrawing.clearCanvas();
      this.fabricDrawing.getCanvas().dispose();
    },
  },
  watch: {
    updated() {
      this.resetCanvas();
      this.initCanvas();
    },
  },
};
</script>
<style scoped>
.canvas-parent {
  overflow-x: auto;
  overflow-y: auto;
}
</style>
