<template>
  <div v-if="item">
    <tour :tourKey="'asbestos_create'" :key="item.assessment_method_id" />

    <v-row v-if="item.contaminate_id" data-cy="item-required-field">
      <v-col class="pt-5">
        <v-row>
          <v-col sm="4" cols="12" data-cy="asbestos-name">
            <v-text-field
              spellcheck="true"
              label="Name*"
              outlined
              v-model.lazy="item.name"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              placeholder="Name of item"
              messages="*Required"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" data-cy="asbestos-link-word">
            <v-text-field
              spellcheck="true"
              outlined
              label="Link word*"
              v-model.lazy="item.link"
              messages="*Required"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('link')"
              data-vv-name="link"
              placeholder="Word linking item to location"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" data-cy="asbestos-location">
            <v-text-field
              spellcheck="true"
              label="Location*"
              messages="*Required"
              outlined
              v-model.lazy="item.description"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('location')"
              data-vv-name="location"
              placeholder="Location of the item"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="item && assessmentMethods">
            <v-select
              label="Assessment Method*"
              v-model="item.assessment_method_id"
              :items="
                assessmentMethods.filter(
                  (assessment) =>
                    assessment.contaminate_id == ASBESTOS &&
                    assessment.id != BMIS_ASBESTOS
                )
              "
              :item-disabled="sampledAsDisable"
              messages="*Required"
              item-text="name"
              item-value="id"
              outlined
              v-validate="'required'"
              :disabled="!!room.is_inaccessible"
              :error-messages="errors.collect('assessment method')"
              data-vv-name="assessment method"
              placeholder="Select assessment method of the item"
              required
              class="assessment-method-step"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="item.assessment_method_id == SAMPLED">
          <v-col data-cy="asbestos-sample-id">
            <v-text-field
              spellcheck="true"
              label="Sample ID*"
              messages="*Required"
              maxlength="10"
              :success="sampleIdInput.success"
              :loading="sampleIdInput.loading"
              v-validate="'required'"
              :error-messages="sampleIdInput.errorMessage"
              data-vv-name="bag code"
              required
              outlined
              placeholder="Enter the item Sample ID"
              v-model.lazy="item.sample.bag_code"
              @change.native="bagCheck"
              :key="1"
              class="sample-id-step"
            ></v-text-field>
          </v-col>
          <v-col lg="6">
            <v-select
              class="analysis-step"
              label="Analysis*"
              :items="analysisTypes.filter((aType) => aType.contaminate_id == ASBESTOS)"
              item-text="name"
              messages="*Required"
              outlined
              item-value="id"
              v-model.lazy="item.sample.analysis_type_id"
              v-validate="'required'"
              :error-messages="errors.collect('analysis type')"
              data-vv-name="analysis type"
              placeholder="Select analysis type for this item"
              required
              :key="2"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="item.assessment_method_id == SAMPLED_AS">
          <v-col v-if="sampledAsItems && sampledAsItems.length > 0">
            <v-select
              label="Sampled as*"
              messages="*Required"
              v-model="item.sampled_as.item_as_id"
              :items="sampledAsItems"
              item-text="sampledAsName"
              outlined
              item-value="id"
              v-validate="'required'"
              :error-messages="errors.collect('sampled as')"
              data-vv-name="sampled as"
              placeholder="Select the item this item is sampled as"
              required
              :key="3"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            item.assessment_method_id == STRONGLY_PRESUMED ||
            item.assessment_method_id == PRESUMED
          "
        >
          <v-col v-if="sampleStatuses">
            <v-select
              label="Presumed asbestos type*"
              messages="*Required"
              v-model="item.presumed_sample.sample_status_id"
              :items="
                sampleStatuses.filter(
                  (status) =>
                    status.id != AWAITING_RESULT && status.contaminate_id == ASBESTOS
                )
              "
              item-text="name"
              outlined
              item-value="id"
              v-validate="'required'"
              :error-messages="errors.collect('sample status')"
              data-vv-name="sample status"
              placeholder="Set the presumed asbestos type"
              required
              :key="4"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="item.assessment_method_id == PREVIOUSLY_SAMPLED">
          <v-col>
            <v-text-field
              data-cy="report-as"
              spellcheck="true"
              label="Reported as*"
              messages="*Required"
              v-model.lazy="item.reported_sample.name"
              v-validate="'required|max:190'"
              counter
              outlined
              maxlength="190"
              placeholder="Name of the report where this item is sampled"
              required
              :key="5"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              spellcheck="true"
              label="Reported Sample ID*"
              messages="*Required"
              v-model.lazy="item.reported_sample.sample_number"
              v-validate="'required|max:190'"
              counter
              outlined
              maxlength="190"
              :error-messages="errors.collect('reported sample number')"
              data-vv-name="reported sample number"
              placeholder="Sample ID from the report where the item was sampled"
              required
              :key="6"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="sampleStatuses">
            <v-select
              label="Sample Status*"
              messages="*Required"
              v-model="item.reported_sample.status_id"
              :items="
                sampleStatuses.filter(
                  (status) =>
                    status.id != AWAITING_RESULT && status.contaminate_id == ASBESTOS
                )
              "
              item-text="name"
              item-value="id"
              outlined
              v-validate="'required'"
              :error-messages="errors.collect('sample status')"
              data-vv-name="sample status"
              placeholder="Select the sample status defined in the report"
              required
              :key="7"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            item.assessment_method_id != INACCESSIBLE &&
            item.assessment_method_id != NOT_SUSPICIOUS
          "
        >
          <v-col lg="6" data-cy="asbestos-amount">
            <v-text-field
              spellcheck="true"
              autocomplete="off"
              label="Amount*"
              v-model="item.amount"
              messages="*Required"
              outlined
              type="number"
              v-validate="'required|decimal'"
              :error-messages="errors.collect('amount')"
              data-vv-name="amount"
              placeholder="Enter the amount found"
              required
              :key="8"
            />
          </v-col>
          <v-col lg="6">
            <v-select
              v-if="item && item.contaminate_id"
              label="Type*"
              :items="amountTypes"
              messages="*Required"
              outlined
              item-text="name"
              item-value="id"
              v-model.lazy="item.amount_type_id"
              v-validate="'required'"
              :error-messages="errors.collect('amount type')"
              data-vv-name="amount type"
              placeholder="Select the amount type"
              required
              :key="9"
            >
              <template v-slot:item="{ item }">
                <div v-if="item" v-html="item.name" />
              </template>
              <template v-slot:selection="{ item }">
                <div v-if="item" v-html="item.name" />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              autocomplete="off"
              spellcheck="true"
              label="Comments"
              v-model="item.comment"
              outlined
              placeholder="Enter comments about the item"
              data-cy="comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-cloak @dragover.prevent>
            <v-file-input
              v-model="file"
              accept="image/*"
              :placeholder="!item.image ? 'Item Image' : 'Upload Image'"
              label="Item Image"
              v-validate="'image'"
              outlined
              :error-messages="errors.collect('image')"
              data-vv-name="image"
              hint="Image of the item"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row justify="center" style="max-width: 500px; margin: auto">
          <v-col>
            <a-img :src="displayImage" v-if="displayImage && file"></a-img>
            <a-img
              v-else-if="item.id"
              :src="`/api/image/item/${item.id}?${item.updated_at}`"
            ></a-img>
          </v-col>
        </v-row>
        <v-row v-if="building.has_multiple_images">
          <v-col v-cloak @dragover.prevent>
            <v-file-input
              :disabled="!file && !item.image"
              v-model="file_alt"
              accept="image/*"
              :placeholder="
                !item.image_alt ? 'Alternate Item Image' : 'Upload Alternate Image'
              "
              label="Alternate Item Image"
              v-validate="'image'"
              outlined
              :error-messages="errors.collect('image')"
              data-vv-name="image"
              hint="Image of the item"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          style="max-width: 500px; margin: auto"
          v-if="(building.has_multiple_images && item.image_alt) || file_alt"
        >
          <v-col>
            <a-img :src="displayImageAlt" v-if="displayImageAlt && file_alt"></a-img>
            <a-img
              v-else-if="item.id"
              :src="`/api/image/item/${item.id}/image_alt?${item.updated_at}`"
            ></a-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";
import Tour from "./Tour.vue";

const INACCESSIBLE_RECOMMENDATION = 7;

import mixin from "../helpers/mixin";

export default {
  props: ["aItem", "buildingItems", "building"],
  mixins: [mixin],
  created() {
    this.SAMPLED = ASSESSMENT_METHODS.SAMPLED;
    this.SAMPLED_AS = ASSESSMENT_METHODS.SAMPLED_AS;
    this.STRONGLY_PRESUMED = ASSESSMENT_METHODS.STRONGLY_PRESUMED;
    this.PRESUMED = ASSESSMENT_METHODS.PRESUMED;
    this.INACCESSIBLE = ASSESSMENT_METHODS.INACCESSIBLE;
    this.PREVIOUSLY_SAMPLED = ASSESSMENT_METHODS.PREVIOUSLY_SAMPLED;
    this.BMIS_ASBESTOS = ASSESSMENT_METHODS.BMIS_ASBESTOS;
    this.NOT_SUSPICIOUS = ASSESSMENT_METHODS.NOT_SUSPICIOUS;
    this.AWAITING_RESULT = 1;
    this.ASBESTOS = 1;
    this.NEGATIVE = 2;
  },
  components: { Tour },
  inject: ["parentValidator"],
  data: () => ({
    displayImage: null,
    displayImageAlt: null,
    file: null,
    file_alt: null,
    sampleIdInput: {
      success: false,
      failed: false,
      loading: false,
      errorMessage: null,
    },
  }),
  methods: {
    ...mapActions(["checkBagCode"]),
    sampledAsDisable(assessmentMethod) {
      if (assessmentMethod.id == this.SAMPLED_AS && this.sampledAsItems.length == 0) {
        return true;
      }
      return false;
    },
    validate() {
      return this.$validator.validateAll().then((result) => {
        if (!result) {
          const sampleIdError = this.errors.collect("bag code");
          this.sampleIdInput.errorMessage = sampleIdError;
          this.$vuetify.goTo(this.invalidInput(this.$validator));
        }
        return result;
      });
    },
    bagCheck() {
      if (!this.item.sample.bag_code) return;
      this.sampleIdInput.loading = true;
      this.checkBagCode({
        building_id: this.$route.params.building,
        bag_code: this.item.sample.bag_code,
        contaminate_id: this.item.contaminate_id,
      })
        .then(() => {
          this.sampleIdInput.error = false;
          this.sampleIdInput.success = true;
          this.sampleIdInput.errorMessage = null;
        })
        .catch((err) => {
          this.sampleIdInput.success = false;
          this.sampleIdInput.failed = true;
          this.sampleIdInput.errorMessage = err.response.data.message;
        })
        .finally(() => {
          this.sampleIdInput.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "room",
      "assessmentMethods",
      "analysisTypes",
      "sampleStatuses",
      "amountTypes",
      "invalidInput",
    ]),
    item: {
      get() {
        return this.aItem || false;
      },
      set(updated) {
        this.$emit("updated:aItem", updated);
      },
    },
    sampledAsItems() {
      if (!this.buildingItems) return;
      let sampledOnly = this.buildingItems.filter((item) => {
        if (item.assessment_method_id == this.SAMPLED && item.sample != null) {
          if (item.id && this.item.id && this.item.id == item.id) {
            return false;
          }
          return true;
        }
      });
      sampledOnly.forEach((item) => {
        if (!item.sample) return;
        item[
          "sampledAsName"
        ] = `${item.sample.bag_code} - ${item.name} ${item.link} ${item.description}`;
      });
      return sampledOnly.sort((a, b) => a.sample.bag_code - b.sample.bag_code);
    },
  },
  watch: {
    file(updated) {
      if (!updated) return;
      this.addFileOptimization(updated).then((result) => {
        this.displayImage = result;
        this.item.file = result;
      });
    },
    file_alt(updated) {
      if (!updated) return;
      this.addFileOptimization(updated).then((result) => {
        this.displayImageAlt = result;
        this.item.file_alt = result;
      });
    },
    "item.assessment_method_id"(updated) {
      if (updated != this.SAMPLED) {
        this.item = {
          ...this.item,
          sample: {
            analysis_type_id: null,
            bag_code: null,
          },
        };
      }
      if (updated != this.SAMPLED_AS) {
        this.item = {
          ...this.item,
          sampled_as: {
            item_id: null,
            item_as_id: null,
          },
        };
      }
      if (
        updated == this.INACCESSIBLE &&
        !this.item.item_recommendations.find(
          (item) => item == INACCESSIBLE_RECOMMENDATION
        )
      ) {
        this.item = {
          ...this.item,
          item_recommendations: [
            ...this.item.item_recommendations,
            INACCESSIBLE_RECOMMENDATION,
          ],
        };
      }
      if (updated != this.PREVIOUSLY_SAMPLED) {
        this.item = {
          ...this.item,
          reported_sample: {
            name: null,
            sample_number: null,
            status_id: null,
          },
        };
      }
    },
    "item.presumed_sample.sample_status_id"() {
      this.item = { ...this.item };
    },
  },
};
</script>
