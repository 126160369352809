<template>
  <v-card v-show="dialog">
    <!-- <v-card-title>
      <v-row>
        <v-col class="pa-0 ma-0" data-cy="polygon-page">
          <v-btn
            data-cy="delete-polygon"
            color="primary"
            outlined
            icon
            class="float-right mb-n1"
            @click.stop="close"
          >
            <v-icon color="error" class="position-sticky">fas fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title> -->
    <v-card-text>
      <v-row>
        <v-col class="text-center ma-0 px-0 pb-16">
          <v-row class="mx-2 pt-3 mb-0 pb-0" data-cy="polygon-action" v-if="!loading">
            <v-col class="px-0 pb-2 ma-0 d-inline justify-center">
              <v-dialog
                v-model="showLabelDialog"
                persistent
                max-width="600px"
                v-if="config.shapes.includes('text')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    data-cy="draw-label"
                    fab
                    class="mx-1 primary textOption"
                    color="primary"
                    title="Draw Label"
                    id="polygon"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon small>fas fa-text</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Label Options</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Label Text"
                            placeholder="Enter label text"
                            v-validate="'required|text'"
                            outlined
                            maxlength="50"
                            required
                            type="text"
                            v-model="labelConfig.text"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            label="Label Size"
                            v-validate="'required|decimal'"
                            outlined
                            maxlength="2"
                            min="10"
                            max="100"
                            required
                            type="number"
                            v-model="labelConfig.size"
                          ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="3">
                          <v-text-field
                            label="Pick a Color"
                            type="color"
                            outlined
                            required
                            v-model="labelConfig.color"
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showLabelDialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="drawText()"
                      :disabled="disableText"
                    >
                      Use
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                small
                data-cy="draw-polygon"
                fab
                class="mx-1 drawingOption primary"
                v-on="on"
                color="primary"
                title="Draw Polygon"
                id="polygon"
                @click="drawShape('polygon')"
              >
                <v-icon small>fas fa-draw-polygon</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="draw-line"
                fab
                class="mx-1 drawingOption primary"
                v-on="on"
                id="polyline"
                color="primary"
                title="Draw Polyline"
                @click="drawShape('polyline')"
              >
                <v-icon small>fas fa-minus</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="reset-zoom"
                fab
                class="mx-1 primary"
                v-on="on"
                id="reset-zoom"
                title="Reset View"
                @click="resetZoom"
              >
                <v-icon small>fas fa-expand</v-icon>
              </v-btn>

              <!-- <div>
              <polygon-text-dialog
                v-bind:tool="tool"
                v-bind:polygonOptions.sync="polygons"
                v-bind:labelOptions.sync="label"
                v-bind:canvasOptions.sync="options"
              ></polygon-text-dialog>
            </div> -->

              <v-btn
                small
                data-cy="complete-polygon"
                fab
                class="mx-1 editOption disabled success"
                id="complete-drawing"
                title="Complete Drawing"
                v-on="on"
                disabled="disabled"
                @click="completeDrawing"
              >
                <v-icon small>fas fa-check</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="undo-polygon"
                fab
                class="mx-1 editOption v-btn--disabled warning"
                id="undo-drawing"
                title="Undo Drawing"
                v-on="on"
                disabled="disabled"
                @click="undoDrawing"
              >
                <v-icon small>fas fa-undo</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="delete-polygon"
                fab
                class="mx-1 editOption v-btn--disabled error"
                v-on="on"
                title="Delete Drawing"
                id="delete-drawing"
                disabled="disabled"
                @click="deleteDrawing"
              >
                <v-icon small>fas fa-trash</v-icon>
              </v-btn>

              <!-- </v-col>
          <v-col class="px-0 pb-2 ma-0 d-inline-flex justify-center"> -->

              <v-btn
                small
                data-cy="zoom-in"
                fab
                class="mx-1 zoom-in primary"
                v-on="on"
                id="zoom-in"
                title="Zoom In"
                @click="zoomIn"
              >
                <v-icon small>fas fa-magnifying-glass-plus</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="zoom-out"
                fab
                class="mx-1 primary"
                v-on="on"
                id="zoom-out"
                title="Zoom Out"
                @click="zoomOut"
              >
                <v-icon small>fas fa-magnifying-glass-minus</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="pan-zoom"
                fab
                class="mx-1 greyed"
                v-on="on"
                id="pan-zoom"
                title="Pan Image"
                @click="panImage"
              >
                <v-icon small>fas fa-arrows-up-down-left-right</v-icon>
              </v-btn>

              <v-btn
                small
                data-cy="reset-zoom"
                fab
                class="mx-1 primary"
                v-on="on"
                id="reset-zoom"
                title="Reset View"
                @click="resetZoom"
              >
                <v-icon small>fas fa-expand</v-icon>
              </v-btn>

              <!-- <v-btn
                fab
                v-on="on"
                @click="close"
                class="mx-1 success"
                title="Save & Close"
              >
                <v-icon data-cy="finish-polygon">fas fa-clipboard-check fa-sm</v-icon>
              </v-btn> -->
            </v-col>
          </v-row>
          <v-row top class="hide-on-mobile">
            <v-col class="text-center">
              <p>
                * Press Alt key and click to pan the floor plan when zooming in and out
              </p>
            </v-col>
          </v-row>
          <v-row class="grey lighten-1 pa-3 ma-0 d-flex justify-center">
            <v-col cols="auto" class="pa-0 ma-0 d-flex justify-center">
              <v-skeleton-loader
                class="mx-auto"
                height="50em"
                width="60em"
                type="image"
                :loading="loading"
              >
                <span></span>
              </v-skeleton-loader>

              <div
                ref="canvasParent"
                v-show="!loading"
                class="rounded canvas-overflow d-flex justify-center"
              >
                <canvas ref="editCanvas" id="edit-canvas"></canvas>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col>
          <v-btn block color="greyed" depressed @click.stop="confirmDialog = true">
            Cancel
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block color="primary" @click="completePolygon"> Save & Close </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card max-width="500">
          <v-card-title class="text-h6 primary">
            <span class="white--text">Confirm Action</span>
          </v-card-title>

          <v-card-text class="text-subtitle-1">
            {{ "Are you sure you want to leave without saving?" }}
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="greyed" depressed @click="confirmDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" data-cy="exit-drawing" @click="close">Proceed</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { POLYGON, LABEL } from "../constants/drawing";
import { FabricDrawing } from "fabric-tool";

export default {
  props: [
    "colors",
    "itemPolygons",
    "backgroundImageUrl",
    "tool",
    "name",
    "single",
    "disableLabel",
    "defaultLabel",
    "dialog",
  ],
  data: () => ({
    loading: true,
    showLabelDialog: false,
    confirmDialog: false,
    canvas: null,
    width: 0,
    height: 0,
    highlights: [],
    selectedPoint: null,
    moving: false,
    shifting: false,
    previous: [0, 0],
    completed: false,
    image: null,
    shapes: [],
    config: {
      canvasEl: "edit-canvas",
      viewOnly: false,
      maxShapes: 1,
      cornerColor: "red",
      cornerStyle: "circle",
      stroke: "red",
      polygonFill: "rgba(255, 99, 71, 0.4)",
      cornerSize: 16,
      radius: 6,
      strokeWidth: 3,
      shapes: ["polygon", "polyline", "text"],
      maxLabels: 1,
      polyLineWidth: 3,
    },
    labelConfig: {
      text: "Default Text",
      size: 20,
      color: "#ff0000",
    },
    fabricDrawing: null,
  }),
  mounted() {
    if (this.fabricDrawing) {
      this.fabricDrawing.clearCanvas();
      this.fabricDrawing.getCanvas().dispose();
    }
    this.initCanvas();
  },
  watch: {
    dialog(updated) {
      if (updated) {
        if (this.fabricDrawing) {
          this.fabricDrawing.clearCanvas();
          this.fabricDrawing.getCanvas().dispose();
        }
        this.initCanvas();
      }
    },
  },
  computed: {
    disableText() {
      return (
        this.labelConfig.text == null ||
        this.labelConfig.text == "" ||
        this.labelConfig.text.trim() == "" ||
        this.labelConfig.size == null ||
        this.labelConfig.size == "" ||
        this.labelConfig.size < 10 ||
        this.labelConfig.size > 100 ||
        this.labelConfig.color == null ||
        this.labelConfig.color == ""
      );
    },
    polygonData: {
      get() {
        return this.itemPolygons;
      },
      set(updated) {
        this.$emit("update:itemPolygons", updated);
      },
    },
  },
  methods: {
    updateView() {
      this.$emit("canvasUpdate", this.canvas);
    },
    undo() {
      //call fabric undo
    },
    clear() {
      this.$nextTick(() => {
        this.draw();
      });
    },
    getRGBA(color) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a - 0.01})`;
    },
    async initCanvas() {
      try {
        this.loading = true;
        this.updateStyle();
        const response = await this.axios.get(this.backgroundImageUrl);
        this.config.backgroundImg = response.data;
        this.fabricDrawing = new FabricDrawing(this.config);
        this.fabricDrawing.setBackgroundImgUrl(this.config.backgroundImg).then(() => {
          this.drawShapes();
          this.loading = false;
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },
    updateStyle() {
      this.config.polygonFill = this.colors.fill
        ? this.getRGBA(this.colors.fill)
        : this.config.polygonFill;
      this.config.stroke = this.colors.stroke
        ? this.getRGBA(this.colors.stroke)
        : this.config.stroke;
      this.config.maxShapes = this.single ? 1 : 50;
      this.config.maxLabels = this.single ? 1 : 50;
      this.disableLabel
        ? (this.config.shapes = ["polygon", "polyline"])
        : (this.config.shapes = ["polygon", "polyline", "text"]);
      this.labelConfig.text = this.defaultLabel ?? "Default Text";
      this.labelConfig.color = this.colors.stroke
        ? this.getRGBA(this.colors.stroke)
        : "#ff0000";
    },

    updateCanvas() {
      this.updateStyle();
      this.fabricDrawing.updateStyle(this.config);
    },
    drawShapes() {
      let shapeData = [];
      if (
        this.polygonData &&
        this.polygonData.polygons &&
        this.polygonData.polygons.length > 0
      ) {
        for (let polygon of this.polygonData.polygons) {
          let points = polygon.points.map((point) => {
            return {
              x: Number(point[0]),
              y: Number(point[1]),
            };
          });
          let shape = {
            type: polygon.type ? polygon.type : "polygon",
            points: points,
          };
          shapeData.push(shape);
        }
      }

      if (
        this.polygonData &&
        this.polygonData.labels &&
        this.polygonData.labels.length > 0
      ) {
        for (let label of this.polygonData.labels) {
          let points = label.points.map((point) => {
            return {
              x: Number(point[0]),
              y: Number(point[1]),
            };
          });
          let shape = {
            type: label.type ? label.type : "text",
            scaleX: label.scaleX ?? 1,
            scaleY: label.scaleY ?? 1,
            angle: label.angle ?? 0,
            fill: this.colors.stroke
              ? this.getRGBA(this.colors.stroke)
              : label.fill ?? "red",
            stroke: this.colors.stroke
              ? this.getRGBA(this.colors.stroke)
              : label.stroke ?? "red",
            fontSize: label.fontSize ?? 30,
            textValue: label.textValue ?? "Default Text",
            points: points,
          };
          shapeData.push(shape);
        }
      }

      if (shapeData.length > 0) {
        this.fabricDrawing?.completeObjects(JSON.stringify(shapeData));
      }
    },

    completePolygon() {
      this.fabricDrawing
        .savePoints()
        .then((shapes) => {
          let shapedata = JSON.parse(shapes);
          let polygonData = [];
          let textData = [];
          shapedata.forEach((shape) => {
            let points = shape.points.map((point) => {
              return [point.x, point.y];
            });

            if (shape.type == "text") {
              textData.push({
                type: shape.type,
                points: points,
                fill: shape.fill,
                stroke: shape.stroke,
                scaleX: shape.scaleX,
                scaleY: shape.scaleY,
                angle: shape.angle,
                textValue: shape.textValue,
                fontSize: shape.fontSize,
              });
            } else {
              polygonData.push({
                type: shape.type,
                points: points,
              });
            }
          });

          this.polygonData = {
            polygons: polygonData,
            labels: textData,
          };
        })
        .catch((err) => {
          console.error("Error occurred:", err);
          this.polygonData = {
            polygons: [],
            labels: [],
          };
        });
    },
    drawText() {
      this.showLabelDialog = false;
      this.drawShape("text");
    },
    drawShape(type, pointRef = null, pointImage = null, color = null, pointType = null) {
      this.fabricDrawing.disableSelection();
      this.fabricDrawing.registerDclickEvent({
        type: type,
        pointRef: pointRef,
        pointType: pointType,
        pointImage: pointImage,
        stroke: color ? color : this.config.stroke,
        textValue: this.labelConfig.text ?? "no text provided".trim(),
        textColor: this.labelConfig.color,
        textSize: this.labelConfig.size,
      });
    },
    undoDrawing() {
      this.fabricDrawing.undo();
    },
    completeDrawing() {
      this.fabricDrawing.complete();
    },
    deleteDrawing() {
      this.fabricDrawing.deleteObject();
    },
    zoomIn() {
      this.fabricDrawing.zoom(1.1);
    },
    zoomOut() {
      this.fabricDrawing.zoom(0.9);
    },
    resetZoom() {
      this.fabricDrawing.resetZoomPan();
    },
    panImage() {
      this.fabricDrawing.enableMobilePan();
    },
    clearDrawing() {
      this.fabricDrawing.isEmpty().then((empty) => {
        if (!empty) {
          this.confirmModal.message = "Are you sure you want to clear the drawing?";
          this.confirmModal.submitTitle = "Clear";
          this.confirmInstance.show();
        }
      });
    },
    close() {
      this.confirmDialog = false;
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<style scoped>
.canvas-overflow {
  overflow: auto;
}
.border {
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
