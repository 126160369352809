<template>
  <div v-if="asbestosProperties">
    <tour tourKey="item_add_scores" :key="toggleStep" />
    <v-row>
      <v-col>
        <h2>
          <span class="score-desc-step">{{ group.name }}</span>
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="aItem.assessment_method_id == 2 && sampledAsItem">
      <v-col class="pt-0 pb-6">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn raised v-on="on" color="warning" @click="setScores">
              Set Scoring
            </v-btn>
          </template>
          <span>Set all scoring to sampled as scores</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-window v-model="toggleStep" touchless>
      <v-window-item
        v-for="(property, i) in asbestosProperties"
        :key="property.id"
        :value="property.id"
      >
        <item-properties
          :aItem.sync="item"
          :property="property"
          v-on:next-step="nextStep"
          :group="`${property.id}-${i}`"
          :sampledAsItem="sampledAsItem"
        ></item-properties>
      </v-window-item>
    </v-window>
    <v-row>
      <v-col class="justify-space-between">
        <v-item-group v-model="toggleStep" class="text-center" mandatory>
          <v-item v-for="n of asbestosProperties" :key="`btn-${n.id}`" :value="n.id">
            <v-btn
              :input-value="n.id == toggleStep"
              icon
              @click="setToggle(n.id)"
              :color="isItemPropertySet(n.id) ? 'primary' : 'warning'"
            >
              <v-icon>{{ n.id == toggleStep ? "fas" : "fal" }} fa-circle</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemProperties from "./ItemProperties.vue";
import Tour from "./Tour.vue";
export default {
  components: {
    ItemProperties,
    Tour,
  },
  inject: ["parentValidator"],
  props: ["aItem", "sampledAsItem"],
  created() {
    this.$validator = this.parentValidator;
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    toggleStep: 1,
  }),
  watch: {
    aItem() {
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters(["asbestos", "asbestosProperties"]),
    item: {
      get() {
        return this.aItem;
      },
      set(updated) {
        this.$emit("update:aItem", updated);
      },
    },
    group() {
      const prop = this.asbestosProperties.find(
        (property) => property.id == this.toggleStep
      );
      const result = !prop
        ? { name: "N/A" }
        : this.asbestos.find((group) => group.id == prop.group_id);
      return result;
    },
  },
  methods: {
    setScores() {
      this.item.scores = this.sampledAsItem.scores.map((sampleScore) => ({
        contaminate_property_id: sampleScore.contaminate_property_id,
        id: sampleScore.id,
      }));
    },
    setToggle(id) {
      this.toggleStep = id;
    },
    nextStep() {
      this.toggleStep++;
      if (
        this.item.scores &&
        this.item.scores.length == 12 &&
        this.toggleStep >= this.item.scores.length
      ) {
        this.$emit("nextStep");
      }
    },
    isItemPropertySet(property) {
      return (
        this.item.scores &&
        this.item.scores.find((score) => score.contaminate_property_id == property)
      );
    },
    scoreUpdate(score) {
      this.toggleStep = score;
    },
  },
};
</script>
