/* eslint-disable no-console */
import Vue from "vue";
import baseState from "./state";

let mutations = {
  RESET_AUTH_STATE: (state) => {
    const auth = {
      reports: null,
      messages: [],
      export: {
        surveys: [],
        buildings: [],
        floors: [],
        rooms: [],
      },
      user: null,
      settings: {
        browse: {
          survey: {
            search: null,
            status: null,
          },
          building: {
            search: null,
            status: null,
          },
        },
        survey: {
          status: null,
        },
        survey_filter: null,
      },
      search: {
        options: { itemsPerPage: 10 },
        buildings: {
          data: [],
          total: 0,
        },
        surveys: {
          data: [],
          total: 0,
        },
      },
      selected: {
        survey: {
          client_id: null,
          contact_id: null,
          surveyor_id: null,
          reviewer_id: null,
          office_id: null,
          name: null,
          job_number: null,
          notes: null,
          image: null,
        },
        building: {
          address: {
            city: null,
            suburb: null,
            postcode: null,
            address: null,
            address2: null,
          },
          name: null,
          survey_date: null,
          description: null,
          phase: null,
          image: null,
          report: null,
          is_within_three_months: null,
          is_answering_questions: null,
          is_residential: null,
          is_urgent_transfer: null,
          laboratory_id: null,
          report_type_id: null,
          has_sample_polygon: true,
          is_lead: null,
          is_asbestos: null,
        },
        floor: {
          building_id: null,
          name: null,
          description: null,
          image: null,
        },
        room: {
          floor_id: null,
          name: null,
          polygon: null,
          image: null,
          note: null,
          label: false,
        },
        item: {
          name: null,
          link: null,
          description: null,
          polygon: null,
          amount: null,
          removal_class_id: null,
          amount_type_id: null,
          assessment_method_id: null,
          presumed_sample: {
            sample_status_id: null,
          },
          layer: {
            polygons: [],
            labels: [],
          },
          room_id: null,
          scores: [],
          item_scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          item_recommendations: [],
          reported_sample: {
            name: null,
            sample_number: null,
            status_id: null,
          },
          sampled_as: {
            item_id: null,
            status_id: null,
          },
          sample: {
            analysis_type_id: null,
            bag_code: null,
          },
        },
        office: {
          name: null,
          address: {
            city: null,
            suburb: null,
            postcode: null,
            address: null,
            address2: null,
          },
        },
        laboratory: {
          name: null,
          address: {
            city: null,
            suburb: null,
            postcode: null,
            address: null,
            address2: null,
          },
        },
        client: {},
        contacts: [],
        contact: null,
      },
      company: null,
      clients: [],
      offices: [],
      users: [],
      laboratories: [],
      surveys: [],
      buildings: [],
      floors: [],
      rooms: [],
      items: [],
    };
    Vue.set(state, "auth", auth);
  },
  RESET_STATE: (state) => {
    for (let prop in baseState) {
      Vue.set(state, prop, baseState[prop]);
    }
  },
  SET_DARK: (state, payload) => {
    Vue.set(state, "dark", payload);
  },
  SET_REPORT_TYPES: (state, payload) => {
    Vue.set(state, "report_types", payload);
  },
  SET_NOTE_TYPES: (state, payload) => {
    Vue.set(state, "note_types", payload);
  },
  SET_SURVEY_STATUS: (state, payload) => {
    Vue.set(state.auth.settings.survey, "status", payload);
  },
  SET_ITEM: (state, payload) => {
    Vue.set(state.auth.selected, "item", payload);
  },
  UPDATE_ITEM: (state, payload) => {
    Vue.set(state.auth.selected, "item", {
      ...state.auth.selected.item,
      ...payload,
    });
  },
  SET_SELECTED_SAMPLED_AS: (state, payload) => {
    Vue.set(state.auth.selected, "sampled_as", payload);
  },
  CLEAR_SAMPLED_AS: (state) => {
    Vue.set(state.auth.selected, "sampled_as", {});
  },
  SET_ITEM_SCORE: (state, payload) => {
    const theScores = state.auth.selected.item.item_scores;
    let itemScores = {};
    for (let i = 0; i < 12; i++) {
      itemScores[i] = 0;
    }
    if (theScores.length > 0) {
      itemScores = { ...itemScores, ...state.auth.selected.item.item_scores };
    }
    let groups = state.contaminates.asbestos;
    for (let i = 0; i < groups.length; i++) {
      let property = groups[i].properties.find(
        ({ id }) => id == payload["property"]
      );
      if (!property) continue;
      for (let x = 0; x < property.scores.length; x++) {
        itemScores[property.id - 1] = payload["score"];
      }
    }
    itemScores = Object.keys(itemScores).map(function (key) {
      return itemScores[key];
    });
    Vue.set(state.auth.selected.item, "item_scores", itemScores);
  },
  SET_ITEM_RECOMMENDATION: (state, payload) => {
    Vue.set(state.auth.selected.item, "item_recommendations", payload);
  },
  SET_AMOUNT_TYPES: (state, payload) => {
    Vue.set(state, "amountTypes", payload);
  },
  SET_SELECTED_PROPERTY: (state, payload) => {
    Vue.set(
      state.auth.selected[payload["type"]],
      payload["property"],
      payload["value"]
    );
  },
  SET_BUILDING: (state, payload) => {
    for (const [key, val] of Object.entries(payload)) {
      if (state.auth.selected.building[key] != val) {
        if (key == "is_asbestos" || key == "is_lead") {
          Vue.set(state.auth.selected.building, key, val ? true : false);
          continue;
        }
        Vue.set(state.auth.selected.building, key, val);
      }
    }
  },
  SET_APPROVAL: (state, payload) => {
    Vue.set(state.auth.selected, "approvals", payload);
  },
  SET_CLIENT: (state, payload) => {
    Vue.set(state.auth.selected, "client", payload);
  },
  SET_CLIENT_CONTACTS: (state, payload) => {
    Vue.set(state.auth.selected, "contacts", payload);
  },
  SET_CONTACT: (state, payload) => {
    Vue.set(state.auth.selected, "contact", payload);
  },
  SET_CONTAMINATE_TYPE: (state, payload) => {
    Vue.set(state, "contaminate_types", payload);
  },
  SET_FLOOR: (state, payload) => {
    for (const [key, val] of Object.entries(payload)) {
      if (state.auth.selected.floor[key] != payload[key]) {
        Vue.set(state.auth.selected.floor, key, val);
      }
    }
  },
  SET_SELECTED_ROOM: (state, payload) => {
    Vue.set(state.auth.selected, "room", { ...payload });
  },
  SET_STATUSES: (state, payload) => {
    Vue.set(state, "statuses", payload);
  },
  SET_ASSESSMENT_METHODS: (state, payload) => {
    Vue.set(state, "assessment_methods", payload);
  },
  SET_SURVEYS: (state, payload) => {
    Vue.set(state.auth, "surveys", payload);
  },
  SET_SURVEY: (state, payload) => {
    Vue.set(state.auth.selected, "survey", {
      ...state.auth.selected.survey,
      ...payload,
    });
  },
  SET_SAMPLE_STATUSES: (state, payload) => {
    Vue.set(state, "sample_statuses", payload);
  },
  SET_BUILDINGS: (state, payload) => {
    let result = payload;
    if (!Array.isArray(payload)) {
      result = [payload];
    }
    Vue.set(state.auth, "buildings", result);
  },
  SET_FLOORS: (state, payload) => {
    Vue.set(state.auth, "floors", payload);
  },
  SET_ROOMS: (state, payload) => {
    Vue.set(state.auth, "rooms", payload);
  },
  SET_ITEMS: (state, payload) => {
    if (!Array.isArray(payload)) {
      payload = [payload];
    }
    Vue.set(state.auth, "items", payload);
  },
  SET_SAMPLED_ITEMS_TO_ITEMS: (state, payload) => {
    if (!Array.isArray(payload)) {
      payload = [payload];
    }
    const items = [...state.auth.items, ...payload].reduce((unique, item) => {
      let result = [];
      const duplicate = unique.findIndex((i) => i.id === item.id);
      if (duplicate != -1) {
        result = [...unique];
        result[duplicate] = item;
      } else {
        result = [...unique, item];
      }
      return result;
    }, []);

    Vue.set(state.auth, "items", items);
  },
  SET_ANALYSIS_TYPES: (state, payload) => {
    Vue.set(state, "analysisTypes", payload);
  },
  SET_NOTIFICATION_TYPE: (state, payload) => {
    Vue.set(state.notification, "type", payload);
  },
  SET_SELECTED_USER: (state, payload) => {
    Vue.set(state.auth.selected, "user", payload);
  },
  SET_NOTIFICATION_SHOW: (state, payload) => {
    Vue.set(state.notification, "show", payload);
  },
  SET_NOTIFICATION_MESSAGE: (state, payload) => {
    Vue.set(state.notification, "message", payload);
  },
  SET_NOTIFICATION_TIMEOUT: (state, payload) => {
    Vue.set(state.notification, "timeout", payload);
  },
  SET_MESSAGES: (state, payload) => {
    Vue.set(state.auth, "messages", payload);
  },
  SET_MESSAGE: (state, payload) => {
    state.auth.messages.push(payload);
  },
  CLEAR_NOTIFICATION: (state) => {
    const notification = {
      type: null,
      show: null,
      message: null,
      timeout: null,
    };
    Vue.set(state, "notification", notification);
  },
  SET_USER: (state, payload) => {
    Vue.set(state.auth, "user", payload);
  },
  SET_USER_COMPANY: (state, payload) => {
    Vue.set(state.auth, "company", payload);
  },
  SET_USER_COMPANY_USERS: (state, payload) => {
    if (payload.users.length == 0) {
      return;
    }
    Vue.set(state.auth, "users", payload.users);
  },
  EDIT_USER_COMPANY_USERS: (state, payload) => {
    if (payload.users.length == 0) {
      return;
    }
    Vue.set(state.auth, "users", payload.users);
  },
  SET_SYSTEM_COMPANY_USERS: (state, payload) => {
    if (payload.users.length == 0) {
      return;
    }
    for (let i in state.companies.data) {
      if (state.companies.data[i].id == payload.id) {
        let companyUsers = {
          ...state.companies.data[i],
          users: payload.users,
        };
        Vue.set(state.companies.data, i, companyUsers);
      }
    }
  },
  SET_COMPANY_USERS: (state, payload) => {
    Vue.set(state.auth, "users", payload);
  },
  SET_ROLES: (state, payload) => {
    let roles = [];
    for (const role of payload) {
      roles.push({ id: role.id, name: role.name });
    }
    Vue.set(state, "roles", roles);
  },
  SET_COMPANIES: (state, payload) => {
    Vue.set(state, "companies", payload);
  },
  SET_COMPANY_OFFICES: (state, payload) => {
    Vue.set(state.auth, "offices", payload);
  },
  SET_OFFICE: (state, payload) => {
    Vue.set(state.auth.selected, "office", payload);
  },
  SET_LABORATORY: (state, payload) => {
    Vue.set(state.auth.selected, "laboratory", payload);
  },
  SET_SYSTEM_COMPANY_LABORATORIES: (state, payload) => {
    if (payload.laboratories.length == 0) {
      return;
    }
    let companyIndex = state.companies.data.findIndex(
      (company) => company.id == payload.id
    );
    let newCompany = {
      ...state.companies.data[companyIndex],
      laboratories: payload.laboratories,
    };
    Vue.set(state.companies.data, companyIndex, newCompany);
  },
  SET_COMPANY_LABORATORIES: (state, payload) => {
    Vue.set(state.auth, "laboratories", payload);
  },
  SET_COMPANY_CLIENTS: (state, payload) => {
    Vue.set(state.auth, "clients", payload);
  },
  SET_ASBESTOS: (state, payload) => {
    Vue.set(state.contaminates, "asbestos", payload);
  },
  SET_ASBESTOS_RECOMMENDATIONS: (state, payload) => {
    Vue.set(state.recommendations, "asbestos", payload);
  },
  SET_BUILDING_SEARCH: (state, payload) => {
    Vue.set(state.auth.search, "buildings", payload);
  },
  SET_SURVEY_SEARCH: (state, payload) => {
    Vue.set(state.auth.search, "surveys", payload);
  },
  CLEAR_SELECTED_SURVEY: (state) => {
    let survey = {
      client_id: null,
      contact_id: null,
      surveyor_id: null,
      reviewer_id: null,
      office_id: null,
      name: null,
      job_number: null,
      notes: null,
      image: null,
    };
    Vue.set(state.auth.selected, "survey", survey);
  },
  CLEAR_SELECTED_BUILDING: (state) => {
    let building = {
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
      },
      name: null,
      date: null,
      description: null,
      phase: null,
      image: null,
      report: null,
      is_within_three_months: null,
      is_answering_questions: null,
      is_residential: null,
      is_urgent_transfer: null,
      laboratory_id: null,
      report_type_id: null,
    };
    Vue.set(state.auth.selected, "building", building);
  },
  CLEAR_SELECTED_ROOM: (state, payload) => {
    let room = {
      floor_id: payload,
      name: null,
      polygon: null,
      image: null,
      note: null,
      layer: {
        polygons: null,
        label: null,
      },
    };
    Vue.set(state.auth.selected, "room", room);
  },
  CLEAR_SELECTED_FLOOR: (state) => {
    let floor = {
      name: null,
      description: null,
      image: null,
    };
    Vue.set(state.auth.selected, "floor", floor);
  },
  CLEAR_SELECTED_ITEM: (state, room) => {
    const item = {
      room_id: room || null,
      name: null,
      link: null,
      description: null,
      polygon: null,
      amount: null,
      removal_class_id: null,
      amount_type_id: 1,
      assessment_method_id: 1,
      presumed_sample: {
        sample_status_id: null,
      },
      layer: {
        polygons: [],
        labels: [],
      },
      item_scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      item_recommendations: [],
      reported_sample: {
        name: null,
        sample_number: null,
        status_id: 1,
      },
      sampled_as: {
        item_id: null,
        status_id: 1,
      },
      sample: {
        analysis_type_id: 1,
        bag_code: null,
      },
    };
    Vue.set(state.auth.selected, "item", item);
  },
  CLEAR_SELECTED_OFFICE: (state) => {
    const office = {
      name: null,
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null,
      },
    };
    Vue.set(state.auth.selected, "office", office);
  },
  CLEAR_SELECTED_LABORATORY: (state) => {
    const laboratory = {
      name: null,
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null,
      },
    };
    Vue.set(state.auth.selected, "laboratory", laboratory);
  },
  CLEAR_SELECTED_CLIENT: (state) => {
    Vue.set(state.auth.selected, "client", {});
  },
  CLEAR_SELECTED_CONTACTS: (state) => {
    Vue.set(state.auth.selected, "contacts", []);
    Vue.set(state.auth.selected, "contact", null);
  },
  SET_BUILDING_PROPERTY: (state, payload) => {
    payload["prop"] == "address"
      ? Vue.set(
        state.auth.selected.building.address,
        payload["data"]["prop"],
        payload["data"]["data"]
      )
      : Vue.set(state.auth.selected.building, payload["prop"], payload["data"]);
  },
  SET_FLOOR_PROPERTY: (state, payload) => {
    Vue.set(state.auth.selected.floor, payload["prop"], payload["data"]);
  },
  SET_SELECTED_ROOM_PROPERTY: (state, payload) => {
    Vue.set(state.auth.selected.room, payload["prop"], payload["data"]);
  },
  REMOVE_ITEM: (state, id) => {
    let filtered = state.auth.items.filter((item) => {
      return item.id != id;
    });
    Vue.set(state.auth, "items", filtered);
  },
  REMOVE_BUILDING: (state, id) => {
    let filtered = state.auth.buildings.filter((building) => {
      return building.id != id;
    });
    Vue.set(state.auth, "buildings", filtered);
  },
  REMOVE_FLOOR: (state, id) => {
    let filtered = state.auth.floors.filter((floor) => {
      return floor.id != id;
    });
    Vue.set(state.auth, "floors", filtered);
  },
  REMOVE_ROOM: (state, id) => {
    let filtered = state.auth.rooms.filter((room) => {
      return room.id != id;
    });
    Vue.set(state.auth, "rooms", filtered);
  },
  SET_BROWSE_SURVEY_SEARCH: (state, payload) => {
    Vue.set(state.auth.settings.browse.survey, "search", payload);
  },
  SET_BROWSE_BUILDING_SEARCH: (state, payload) => {
    Vue.set(state.auth.settings.browse.building, "search", payload);
  },
  SET_BROWSE_SURVEY_STATUS: (state, payload) => {
    Vue.set(state.auth.settings.browse.survey, "status", payload);
  },
  SET_BROWSE_BUILDING_STATUS: (state, payload) => {
    Vue.set(state.auth.settings.browse.building, "status", payload);
  },
  SET_BROWSE_BUILDING_REPORT_TYPE: (state, payload) => {
    Vue.set(state.auth.settings.browse.building, "report_type", payload);
  },
  SET_LOADING: (state, value) => {
    Vue.set(state, "isLoading", value);
  },
  SET_TABLE_OPTIONS: (state, value) => {
    Vue.set(state.auth.search, "options", value);
  },
  SET_2FA: (state, value) => {
    state.google_2fa = value;
  },
  SET_2FA_VERIFIED: (state, value) => {
    state.verified_2fa = value;
  },
  SET_2FA_LOADED: (state, value) => {
    state.loaded_2fa = value;
  },
  SET_DEFAULT_2FA: (state) => {
    state.google_2fa = true;
    state.verified_2fa = false;
    state.loaded_2fa = false;
  },
};

export default mutations;
