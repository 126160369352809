<template>
  <v-dialog v-model="dialog" fullscreen scrollable persistent>
    <tour tourKey="polygon" />
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-skeleton-loader
          class="mx-auto"
          height="50em"
          width="60em"
          type="image"
          :loading="loading"
        >
          <span></span>
        </v-skeleton-loader>
        <polygon-display
          class="polygon-step"
          v-if="url || drawingUrl"
          :loading.sync="loading"
          :updated="updated"
          :backgroundImageUrl="url"
          :polygonData="polygonData"
          :colors="colors"
        ></polygon-display>
      </span>
    </template>

    <polygon-canvas
      ref="polygonCanvas"
      :colors="colors"
      v-bind:itemPolygons.sync="polygonData"
      :name="aItem.name"
      :single="single"
      :disableLabel="disableLabel"
      :defaultLabel="bagCode"
      :is_room="is_room"
      @canvasUpdate="updateDisplay"
      :backgroundImageUrl="drawingUrl"
      v-bind:tool.sync="tool"
      v-bind:dialog.sync="dialog"
    ></polygon-canvas>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import PolygonCanvas from "./PolygonDrawingCanvas";
import PolygonDisplay from "./PolygonDisplay";
import Tour from "./Tour";
import PolygonTextDialog from "./PolygonTextDialog";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";
import { colors } from "vuetify/lib";

export default {
  components: {
    PolygonDisplay,
    PolygonCanvas,
    Tour,
    PolygonTextDialog,
  },
  props: {
    url: {
      type: String,
      default: null,
      required: false,
    },
    drawingUrl: {
      type: String,
      default: null,
      required: false,
    },
    setColor: {
      type: Object,
      default: null,
      // default: () => ({
      //   fill: { r: 255, g: 99, b: 71, a: 0.2 },
      //   stroke: { r: 255, g: 0, b: 0, a: 0.6 },
      // }),
      required: false,
    },
    aItem: {
      type: Object,
      default: null,
      required: true,
    },
    selectedTool: {
      type: Number,
      default: null,
      required: false,
    },
    tools: {
      type: Array,
      default: () => [1, 2, 3],
      required: false,
    },
    single: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_room: {
      type: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: Boolean,
      default: true,
      required: false,
    },
    buildingSize: {
      type: Number,
      default: 10,
      required: false,
    },
    allow_label: {
      type: Boolean,
      default: false,
      required: false,
    },
    step: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  watch: {},
  mounted() {
    this.addLabel = this.allow_label;
    this.colors = this.getColors();
  },
  data: () => ({
    addLabel: false,
    reader: null,
    loading: true,
    image: null,
    canvas: null,
    fab: false,
    value: null,
    dialog: false,
    drawer: false,
    tool: null,
    updated: 0,
    colors: {
      fill: { r: 255, g: 99, b: 71, a: 0.2 },
      stroke: { r: 255, g: 0, b: 0, a: 0.6 },
    },
  }),
  computed: {
    bagCode() {
      return this.aItem.bag_code ?? this.aItem.sample?.bag_code;
    },
    polygonData: {
      get() {
        return this.aItem.layer;
      },
      set(updated) {
        this.dialog = false;
        this.$emit("update:aItem", {
          ...this.aItem,
          layer: { polygons: updated.polygons, labels: updated.labels },
        });
        this.loading = true;
        ++this.updated;
      },
    },
  },
  methods: {
    ...mapActions(["getImageData"]),
    updateDisplay() {
      this.updated++;
    },
    undo() {
      this.$refs.polygonCanvas.undo();
    },
    clear() {
      this.$refs.polygonCanvas.clear();
    },
    isNumeric(n) {
      return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
    },
    getColors() {
      if (this.setColor) {
        return this.setColor;
      }

      let colors = {
        fill: { r: 255, g: 99, b: 71, a: 0.2 },
        stroke: { r: 255, g: 0, b: 0, a: 1 },
      };

      if (this.aItem.contaminate_id == 1) {
        if (this.aItem.assessment_method_id == ASSESSMENT_METHODS.INACCESSIBLE) {
          colors.fill = { r: 255, g: 165, b: 0, a: 0.1 };
          colors.stroke = { r: 255, g: 165, b: 0, a: 1 };
        } else if (
          [ASSESSMENT_METHODS.STRONGLY_PRESUMED, ASSESSMENT_METHODS.PRESUMED].includes(
            this.aItem.assessment_method_id
          ) &&
          this.aItem.presumed_sample &&
          this.aItem.presumed_sample.sample_status_id == 2
        ) {
          colors.fill = { r: 76, g: 166, b: 76, a: 0.1 };
          colors.stroke = { r: 76, g: 166, b: 76, a: 1 };
        } else if (
          [ASSESSMENT_METHODS.STRONGLY_PRESUMED, ASSESSMENT_METHODS.PRESUMED].includes(
            this.aItem.assessment_method_id
          ) &&
          this.aItem.presumed_sample &&
          this.aItem.presumed_sample.sample_status_id &&
          this.aItem.presumed_sample.sample_status_id != 2
        ) {
          return colors;
        } else if (
          this.aItem.sample &&
          this.aItem.sample.status &&
          this.aItem.sample.status.id == 2
        ) {
          colors.fill = { r: 76, g: 166, b: 76, a: 0.1 };
          colors.stroke = { r: 76, g: 166, b: 76, a: 1 };
        } else {
          colors.fill = { r: 255, g: 99, b: 71, a: 0.2 };
          colors.stroke = { r: 255, g: 0, b: 0, a: 1 };
        }
      }

      return colors;
    },
  },
  watch: {
    step(value) {
      if (value == 2) {
        this.loading = true;
        this.updated++;
      }
    },
    aItem: {
      handler() {
        this.loading = true;
        this.colors = this.getColors();
        this.updated++;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.canvas-parent {
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
}

/* Hide the row on mobile and iPad screens */
@media (max-width: 820px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
